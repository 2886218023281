<!-- =========================================================================================
    File Name: Error500.vue
    Description: 500 Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="mt-32">
      <inactive-link :message="'This link is no longer active. Please contact the person who sent it to you for more information.'"/>
    </div>
</template>
<script>
  import InactiveLink from "../guest/components/InactiveLink";

  export default {
    components: {
      InactiveLink
    }
  }
</script>
